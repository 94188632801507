import SubPageLayout from "ui/layout/SubPageLayout"
import styles from './anamnesis.module.scss';
import Button from '../../../ui/elements/Button/Button';
import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from "@ionic/react";
import { useContextTranslation } from '../../../ui/translation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import { useAnamnesisState } from './AnamnesisStore';

const AnamnesisSuccess = () => {
  const t = useContextTranslation('page.anamnesis');
  const history = useHistory();
  const {shoppingCartId, token} = useAnamnesisState()
  
  useIonViewWillEnter(() => {
    if(!token || !shoppingCartId) {
      history.replace('/bookings')
    }
  }, [token, shoppingCartId])

  return (
    <SubPageLayout className={styles.anamnesisLayout}>
      <IonGrid className={styles.successWrapper}>
        <IonRow className={styles.successContent}>
          <IonCol size="12" className={styles.headingWrapper}>
            <FontAwesomeIcon className={styles.successIcon} icon={faCheckCircle} />
            <div className={styles.textCenter}>Thank you for filling out the form! </div>
          </IonCol>
        </IonRow>
        <div className={styles.successButtons}>
          <Button
            onClick={() => {
              history.replace(`/bookings/${shoppingCartId}/${token}`);
            }}
            className={styles.transparentBtn}
          >
            {t('booking_details')}
          </Button>
          <Button
            onClick={() => {
              history.push('/home');
            }}
            color="primary"
          >
            {t('home')}
          </Button>
        </div>
      </IonGrid>
    </SubPageLayout>
  )


}
 export default AnamnesisSuccess