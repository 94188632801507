import { Control, useController } from 'react-hook-form';
import styles from './anamnesis.module.scss';
import useConfig, { InjuryFormValues, whenOption } from './questionnaireFormConfig';
import { useState } from 'react';
import cx from 'classnames';
import { IonItem, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import React from 'react';
import { useContextTranslation } from '../../../ui/translation';

const InjuryDetailsForm = ({ control, bodyPartName }: { control: Control<InjuryFormValues>, bodyPartName: string }) => {
  const t = useContextTranslation('page.anamnesis.bodyPart');

  const questionnaireFormConfig = useConfig();
  const { field } = useController<InjuryFormValues>({
    control,
    name: bodyPartName,
  });

  const [formVale, setValue] = useState<{ when?: whenOption, side: string }[] | []>(field.value || []);
  const BodyPart = questionnaireFormConfig.bodyParts.find(part => part.name === bodyPartName);

  if (!BodyPart) {
    // TODO handle error when body part is not found
    return <></>;
  }

  return (
    <div className={styles.injuryDetails}>
      <BodyPart.icon className={styles.injuryDetailsIcon} />
      <h3 className={styles.injuryDetailsHeading}>
        {t(BodyPart.name)}
      </h3>
      <div>
        <h4 className={styles.injurySubheading}>{t(BodyPart.name === 'back' ? 'select_back_part' : 'select_side')}</h4>
        <div className={styles.detailOptionsWrapper}>
          {BodyPart.details.options.map((option) => (
            <label
              className={cx({
                [styles.partOption]: true,
                [styles.checked]: formVale.find(partElement => partElement.side === option),
              })}
              key={option}
            >
              <p className={styles.sideLabel}>
                {t(option)}
              </p>
              <input
                className={styles.hiddenCheckBox}
                onChange={(e) => {
                  let valueCopy = [...formVale];
                  if (e.target.checked) {
                    valueCopy = [...valueCopy, { side: e.target.value }];
                  }
                  if (!e.target.checked) {
                    valueCopy = valueCopy.filter(val => (val.side !== e.target.value));
                  }
                  field.onChange(valueCopy);
                  setValue(valueCopy);
                }}
                type="checkbox"
                checked={Boolean(formVale.find(v => v.side === option))}
                value={option}
              />
            </label>
          ))}
        </div>
      </div>
      <div className={styles.checkbox}>
        {formVale ? formVale.map(side => (
          <React.Fragment key={side.side}>
            <h4 className={styles.injurySubheading} > {t(side.side)}</h4>
            <IonRadioGroup
              value={field.value.find(e => e.side === side.side)?.when}
              key={side.side}
              onIonChange={(e) => {
                const valueCopy = [...field.value];
                const index = valueCopy.findIndex(partElement => partElement.side === side.side)
                valueCopy[index] = {
                  side: side.side,
                  when: e.detail.value as whenOption,
                }
                field.onChange(valueCopy);
                setValue(valueCopy);
              }}
            >
              <IonList lines="none" mode="ios">
                <IonItem key={'current'}>
                  <IonRadio
                    value={'current'}
                    labelPlacement="end"
                    justify="start"
                  >
                    <p className={styles.optionLabel}>
                      {t('current')}
                    </p>
                  </IonRadio>
                </IonItem>
                <IonItem key={'past'}>
                  <IonRadio
                    value={'past'}
                    labelPlacement="end"
                    justify="start"
                  >
                    <p className={styles.optionLabel}>
                    </p>
                    {t('past')}
                  </IonRadio>
                </IonItem>
              </IonList>
            </IonRadioGroup>
          </React.Fragment>
        )) : null}
      </div>
    </div>
  );
};

export default InjuryDetailsForm;