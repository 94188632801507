import { Control, useController } from 'react-hook-form';
import styles from './anamnesis.module.scss';
import cx from 'classnames';
import useConfig from './questionnaireFormConfig';
import { useState } from 'react';
import { useContextTranslation } from '../../../ui/translation';

const BodyPartForm = ({ control }: { control: Control<{ bodyParts: string[] }> }) => {
  const questionnaireFormConfig = useConfig();
  const t = useContextTranslation('page.anamnesis.bodyPart');
  const { field } = useController({
    control,
    name: 'bodyParts',
  });

  const [value, setValue] = useState(field.value || []);
  return (

    <div className={styles.bodyPart}>
      <h3 className={styles.anamnesisFormHeading}>
        {t('heading')}
      </h3>
      <div className={styles.bodyPartContent}>
        {
          questionnaireFormConfig.bodyParts.map(BodyPart => {
            return (
              <label key={BodyPart.name} aria-label={BodyPart.name}>
                <div
                  className={cx({
                    [styles.bodyPartItem]: true,
                    [styles.bodyPartChecked]: value.includes(BodyPart.name),
                  })}
                >
                  <BodyPart.icon className={styles.bodyPartIcon} aria-hidden="true" />
                  <p className={styles.buttonLabel}>
                    {t(BodyPart.name)}
                  </p>
                </div>
                <input
                  id={BodyPart.name}
                  className={styles.hiddenCheckBox}
                  onChange={(e) => {
                    // TODO REFACTOR, ABSTRACTION?
                    if(e.target.value === 'none') {
                      field.onChange([e.target.value]);
                      setValue([e.target.value]);
                      return
                    }
                    let valueCopy = [...value].filter(val => val !== 'none');
                    if (e.target.checked) {
                      valueCopy = [...valueCopy, e.target.value];
                    }
                    if (!e.target.checked) {
                      valueCopy = valueCopy.filter(val => (val !== e.target.value));
                    }
                    field.onChange(valueCopy);
                    setValue(valueCopy);
                  }}
                  key={BodyPart.name}
                  type="checkbox"
                  checked={value.includes(BodyPart.name)}
                  value={BodyPart.name}
                />
              </label>
            );
          })
        }
      </div>
    </div>
  );
};

export default BodyPartForm;