// TODO refactor
/* eslint-disable  @typescript-eslint/no-explicit-any */

import { ReactComponent as KneeIcon } from './images/KneeIcon.svg';
import { ReactComponent as AnkleJointIcon } from './images/AnkleJointIcon.svg';
import { ReactComponent as BackIcon } from './images/BackIcon.svg';
import { ReactComponent as ElbowIcon } from './images/ElbowIcon.svg';
import { ReactComponent as HipIcon } from './images/HipIcon.svg';
import { ReactComponent as NoneIcon } from './images/NoneIcon.svg';
import { ReactComponent as ShoulderIcon } from './images/ShoulderIcon.svg';
import { ReactComponent as WristIcon } from './images/WristIcon.svg';
import BodyPartForm from './BodyPartForm';
import VisitReasonForm from './VisitReasonForm';
import { z } from 'zod';
import { useAnamnesisState } from './AnamnesisStore';
import InjuryDetailsForm from './InjuryDetailsForm';
import { useMemo } from 'react';
import { atom, useAtom } from 'jotai';
import { useHistory } from 'react-router';
import { Control } from 'react-hook-form';
import client from '../../../infrastructure/apollo';
import { gql } from '@apollo/client';
import { ObjectID } from '../../types';
import { useContextTranslation } from '../../../ui/translation';
import { useIonAlert } from '@ionic/react';

export type whenOption = 'current' | 'past'
export type InjuryFormValues = {
  [key: string]: { when?: whenOption, side: string }[]
}

export type BodyPart = Record<string, {
  sides: {
    side: string
    when: whenOption
  } | null
}>

type ClientAnamnesis = {
  // TODO replace with enum?
  affectedBodyParts: string[] | null;
  bodyPartInfo: BodyPart | null;
  reasonsForVisit: string[];
}

type ClientIdentifier = {
  clientEmail: string
  clientId: ObjectID
  lastShoppingCartToken: string
}
type Config = {
  reasons: string[],
  bodyParts: {
    name: string,
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
    details: {
      heading: string,
      options: string[],
    },
  }[],
  form: {
    //TODO fix types
    step: string,
    component: ({ control, bodyPartName }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control: Control<any>, bodyPartName?: string
    }) => JSX.Element
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (setPage: (page: number) => void, values: any) => void
    onBack: (setPage: (page: number) => void) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: any
  }[]
}

const sides = [
  'left',
  'right',
];

const UpdateClientAnamnesisMutation = gql`
    mutation UpdateClientAnamnesisMutation( $anamnesis: ClientAnamnesisInput!, $identifier: ClientIdentifier!) {
        updateClientAnamnesis(
            anamnesis: $anamnesis
            identifier: $identifier
        )
    }
`;

const useUpdateClientAnamnesis = () => (async (
  anamnesis: ClientAnamnesis, identifier: ClientIdentifier,
) => {
  await client.mutate({
    mutation: UpdateClientAnamnesisMutation,
    variables: {
      anamnesis,
      identifier,
    },
  });
});


const useConfig = () => {
  const { clientData, reason, bodyParts, bodyPartInfo, setReason, setBodyParts, setBodyPartInfo } = useAnamnesisState();
  const history = useHistory();
  const updateClientAnamnesis = useUpdateClientAnamnesis();
  const mt = useContextTranslation('misc');
  const [showAlert] = useIonAlert();


  const defaultConfig: Config = {
    reasons: [
      'pain_management',
      'flexibility',
      'prevention',
      'muscle_tension',
      'other',
    ],
    bodyParts: [
      {
        name: 'ankleJoint',
        icon: AnkleJointIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'knee',
        icon: KneeIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'back',
        icon: BackIcon,
        details: {
          heading: 'select_side',
          options: [
            'low',
            'mid',
            'neck',
          ],
        },
      },
      {
        name: 'hip',
        icon: HipIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'shoulder',
        icon: ShoulderIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'elbow',
        icon: ElbowIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'wrist',
        icon: WristIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
      {
        name: 'none',
        icon: NoneIcon,
        details: {
          heading: 'select_side',
          options: sides,
        },
      },
    ],
    form: [
      {
        step: 'selectReason',
        component: VisitReasonForm,
        onSubmit: (setPage: (page: number) => void, values: { reasons: string[] }) => {
          setReason(values.reasons);
          setPage(1);
        },
        onBack: () => {
          history.goBack();
        },
        schema: z.object({
          reasons: z.string().array().min(1),
        }),
      },
      {
        step: 'selectBodyPart',
        component: BodyPartForm,
        onSubmit: (setPage, values: { bodyParts: string[] }) => {
          if (values.bodyParts.includes('none')) {
            if (!reason || !clientData || !values) {
              void showAlert(mt('something_went_wrong'));
              return;
            }
            let parsedBodyParts = {} as BodyPart[];
            for (const [key, value] of Object.entries(values)) {
              parsedBodyParts = {
                ...parsedBodyParts, [key]: { sides: value },
              };
            }
            updateClientAnamnesis(
              {
                bodyPartInfo: null,
                affectedBodyParts: null,
                reasonsForVisit: reason,
              },
              clientData,
            ).then(() => {
              history.replace('/anamnesis/success');
            }).catch(() => {
              void showAlert(mt('something_went_wrong'));
              return;
            });
            return;
          }
          setBodyParts(values.bodyParts);
          setPage(2);
        },
        onBack: (setPage: (page: number) => void) => {
          setPage(0);
        },
        schema: z.object({
          bodyParts: z.string().array().min(1),
        }),
      },
      ...(bodyParts?.map((part, index) => {
        return {
          step: 'dynamicInjuryDetails',
          component: (p: any) => InjuryDetailsForm({ control: p.control, bodyPartName: part }),
          onSubmit: (setPage: (page: number) => void, values: any) => {
            if (!reason || !clientData || !values) {
              void showAlert(mt('something_went_wrong'));
              return;
            }
            if (index + 2 > bodyParts.length) {
              // TODO remove as
              let parsedBodyParts = bodyPartInfo ?  bodyPartInfo : {} as BodyPart;
              for (const [key, value] of Object.entries(values)) {
                parsedBodyParts = {
                  ...parsedBodyParts, [key]: { sides: value as any },
                };
              }
              updateClientAnamnesis(
                {
                  bodyPartInfo: parsedBodyParts,
                  affectedBodyParts: Object.keys(parsedBodyParts),
                  reasonsForVisit: reason,
                },
                clientData,
              ).then(() => {
                history.replace('/anamnesis/success');
              }).catch(() => {
                void showAlert(mt('something_went_wrong'));
              });
              return;
            }
            let parsedBodyParts = bodyPartInfo ?  bodyPartInfo : {} as BodyPart;
            for (const [key, value] of Object.entries(values)) {
              parsedBodyParts = {
                ...parsedBodyParts, [key]: { sides: value as any },
              };
            }
            setBodyPartInfo(parsedBodyParts);
            setPage(index + 3);
          },
          onBack: (setPage: (page: number) => void) => {
            if (index === 0) {
              setConfig(prev => {
                return {
                  ...prev,
                  form: prev.form.slice(0, 2),
                };
              });
            }
            setPage(index + 1);
          },
          schema: z.object({
            [part]: z.object({
              side: z.string(),
              when: z.string(),
            }).array().min(1),
          }),
        };
      }) || [])
    ],
  };

  const configAtom = useMemo(() => atom(defaultConfig), [reason, bodyParts, bodyPartInfo]);
  const [config, setConfig] = useAtom<Config>(configAtom);

  return config;
};

export default useConfig;