import styles from './home.module.scss';

import {
  isPlatform,
  useIonViewWillEnter,
} from '@ionic/react';
import type { IErrorLoggingService } from 'application/services/IErrorLoggingService';
import { loggingServiceSymbol } from 'application/services/IErrorLoggingService';
import type INotificationService from 'application/services/INotificationService';
import { useAuth } from 'application/state/AuthProvider';
import type IClientMetadataAdapter from 'application/state/IClientMetadataAdapter';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import i18n from 'infrastructure/i18n';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import MainLayout from 'ui/layout/MainLayout';
import { useContextTranslation } from 'ui/translation';
import injectables from '../../injectables';
import type IOnboardingService from '../../services/IOnboardingService';
import LocationTile from 'ui/theme/images/Location.jpeg';
import AtHomeTile from './images/AtHome.jpg';
import VouchersTile from 'ui/theme/images/Voucher.jpg';
import PromoBanner from 'application/pages/Home/PromoBanner';
import StretchTile from './images/stretch.png';
import StretchAtHomeTile from './images/stretch_at_home.jpeg';
import classNames from 'classnames';

const Home: React.FC = () => {
  const t = useContextTranslation('page.home');
  const history = useHistory();
  const [onboarding] = useInject<IOnboardingService>(
    injectables.services.OnboardingService,
  );
  const [notificationRequestRejected, setNotificationRequestRejected] =
    React.useState<boolean>(false);
  const [notifications] = useInject<INotificationService>(
    injectables.services.NotificationService,
  );
  const [metadata] = useInject<IClientMetadataAdapter>(
    injectables.state.ClientMetadataAdapter,
  );
  const [loggingAdapter] =
    useInject<IErrorLoggingService>(loggingServiceSymbol);
  const { isAuthenticated } = useAuth();

  useIonViewWillEnter(() => {
    if (onboarding.shouldRedirectToOnboarding() && isPlatform('mobile')) {
      history.replace('/onboarding');
    }
    if (!notifications.getToken() && !notificationRequestRejected) {
      notifications
        .registerDevice(() => {
          if (isAuthenticated) {
            metadata
              .sync({
                notificationToken: notifications.getToken(),
                locale: i18n.language,
              })
              .catch((e) => {
                loggingAdapter.traceException(e);
              });
          }
        })
        .catch(() => {
          setNotificationRequestRejected(true);
        });
    }
  }, [onboarding, notifications]);

  const { clearData } = useTreatmentBuilder();

  useIonViewWillEnter(() => {
    clearData();
  }, []);

  return (
    <MainLayout>
      <PromoBanner />
      <Link className={styles.routeType} to="/vouchers">
        <img
          className={styles.tilePhoto}
          src={VouchersTile}
          alt={t('headers.treatments')}
        />
        <header className={styles.routeDescription}>
          {t('headers.title_vouchers')}
          {t('headers.vouchers')}
        </header>
      </Link>
      <Link className={styles.routeType} to="/locations">
        <img
          className={styles.tilePhoto}
          src={LocationTile}
          alt={t('headers.treatments')}
        />
        <header className={styles.routeDescription}>
          {t('headers.title_locations')}
          {t('headers.locations')}
        </header>
      </Link>
      <Link className={styles.massageRoute} to="/treatments">
        <img
          className={styles.tilePhoto}
          src={AtHomeTile}
          alt={t('headers.treatments')}
        />
        <header className={styles.routeDescription}>
          {t('headers.title')}
          {t('headers.treatments')}
        </header>
      </Link>
      <Link className={styles.routeType} to="/assisted-stretch">
        <img
          className={styles.tilePhoto}
          src={StretchTile}
          alt={t('headers.treatments')}
        />
        <header className={styles.routeDescription}>
          {t('headers.stretch')}
        </header>
      </Link>
      <section className={styles.routeType}>
        <span className={styles.badgeWrapper}>
          <img
            className={classNames([styles.stretchHome, styles.tilePhoto])}
            src={StretchAtHomeTile}
            alt={t('headers.stretch_home')}
          />
          <p className={styles.tileBadge}> {t('headers.coming_soon')}</p>
        </span>
        <header className={styles.routeDescription}>{t('headers.stretch_home')}</header>
      </section>
    </MainLayout>
  );
};

export default Home;
