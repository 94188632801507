import {
  IonCol,
  IonGrid,
  IonInput,
  IonLoading,
  IonRow,
  useIonViewWillEnter,
} from '@ionic/react';
import type ICredentialsLoginAdapter from 'application/pages/Login/ICredentialsLoginAdapter';
import { useAuth } from 'application/state/AuthProvider';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import useAction from 'application/state/useAction';
import { useVoucherContext } from 'application/state/VoucherContext';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import SubPageLayout from 'ui/layout/SubPageLayout';
import { useContextTranslation } from 'ui/translation';

import injectables from '../injectables';
import type IRegistrationAdapter from './IRegistrationAdapter';
import { InvalidCodeError } from './IRegistrationAdapter';
import Button from 'ui/elements/Button/Button';

export interface ConfirmAccountLocationState {
  email?: string;
  password?: string;
}

const ConfirmAccount: React.FC = () => {
  const t = useContextTranslation('page.confirm_account');
  const [code, setCode] = React.useState<string>('');
  const history = useHistory();
  const location = useLocation<ConfirmAccountLocationState>();
  const treatment = useTreatmentBuilder();
  const voucher = useVoucherContext();
  const auth = useAuth();

  const [adapter] = useInject<IRegistrationAdapter>(
    injectables.RegistrationAdapter,
  );
  const [loginAdapter] = useInject<ICredentialsLoginAdapter>(
    injectables.CredentialsLoginAdapter,
  );

  const action = useAction<void>(
    async () => {
      const { email, password } = location.state;
      if (!email) {
        throw new Error('email_not_provided');
      }
      await adapter.confirmAccount(email, code);

      if (password) {
        const accessToken = await loginAdapter.getAccessToken(email, password);
        await auth.login(accessToken);
        const {
          length,
          location: treatmentLocation,
          experts,
          slotTime,
          details,
          locationId,
        } = treatment;
        if (treatmentLocation && experts && details && slotTime && length) {
          history.replace(
            locationId ? '/booking/payment' : '/booking/address',
            { direction: 'forward' },
          );
        } else if (voucher.voucherAmount && voucher.voucherValue) {
          history.replace('/vouchers/payment', { direction: 'forward' });
        } else {
          history.replace('/home', { direction: 'forward' });
        }
      } else {
        history.replace('/login', { direction: 'forward' });
      }
    },
    React.useCallback(() => {
      if (!location.state?.password) {
        history.replace('/login', { direction: 'forward' });
      }
    }, [history, location.state]),
  );

  useIonViewWillEnter(() => {
    if (auth.isAuthenticated) {
      history.replace('/home');
    }
    if (!location.state?.email) {
      history.replace('/login');
    }
  }, [location.state]);

  return (
    <SubPageLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          action.trigger();
        }}
      >
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <h1>{t('title')}</h1>
              <p>{t('description')}</p>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <IonInput
                value={code}
                className="code-input form-input"
                onIonInput={(e) => setCode(e.detail.value || '')}
                type="text"
                required
                inputmode="numeric"
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <Button disabled={!code.length} type="submit">
                {t('submit_label')}
              </Button>
              {action.error && (
                <div className="form-error">
                  {action.error instanceof InvalidCodeError
                    ? t('error_invalid_code')
                    : t('error_unknown')}
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonLoading isOpen={action.loading} message={t('loading')} />
      </form>
    </SubPageLayout>
  );
};

export default ConfirmAccount;
