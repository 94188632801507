import * as React from 'react';
import { useCallback } from 'react';

import { atomWithReducer } from 'jotai/utils';
import { useAtom } from 'jotai';
import { BodyPart } from './questionnaireFormConfig';

// TODO add reason types string and other
type ClientData = {
  clientEmail: string;
  clientId: string;
  lastShoppingCartToken: string;
};

interface AnamnesisStateValues {
  reason: string[] | null;
  bodyParts: string[] | null;
  bodyPartInfo: BodyPart | null;
  shoppingCartId: string | null;
  token: string | null;
  clientData: ClientData | null;
}

const defaultValues: AnamnesisStateValues = {
  reason: null,
  bodyParts: null,
  bodyPartInfo: null,
  shoppingCartId: null,
  token: null,
  clientData: null,
};

interface AnamnesisStateValuesWithActions extends AnamnesisStateValues {
  setReason: (reason: string[] | null) => void;
  setBodyParts: (bodyPart: string[] | null) => void;
  setBodyPartInfo: (bodyPartInfo: BodyPart | null) => void;
  setShoppingCartId: (shoppingCartId: string | null) => void;
  setToken: (token: string | null) => void;
  setClientData: (clientData: ClientData | null) => void;
  clearData: () => void;
}

type SetReasonAction = {
  type: 'SET_REASON';
  payload: {
    reason: string[] | null;
  };
};

type SetBodyPartsAction = {
  type: 'SET_BODY_PARTS';
  payload: {
    bodyParts: string[] | null;
  };
};

type SetBodyPartInfoAction = {
  type: 'SET_BODY_PART_INFO';
  payload: {
    bodyPartInfo: BodyPart | null;
  };
};

type setShoppingCartId = {
  type: 'SET_SHOPPING_CART_ID';
  payload: {
    shoppingCartId: string | null;
  };
};

type SetToken = {
  type: 'SET_TOKEN';
  payload: {
    token: string | null;
  };
};

type SetClientData = {
  type: 'SET_CLIENT_DATA';
  payload: {
    clientData: ClientData | null;
  };
};

type ClearAction = {
  type: 'CLEAR_DATA';
  payload: null;
};

type AnamnesisAction =
  | SetReasonAction
  | SetBodyPartsAction
  | SetBodyPartInfoAction
  | setShoppingCartId
  | SetToken
  | SetClientData
  | ClearAction;

const anamnesisReducer: React.Reducer<AnamnesisStateValues, AnamnesisAction> = (
  prevState,
  action,
) => {
  switch (action.type) {
    case 'SET_REASON': {
      return {
        ...prevState,
        reason: action.payload.reason,
      };
    }

    case 'SET_BODY_PARTS': {
      return {
        ...prevState,
        bodyPartInfo: null,
        bodyParts: action.payload.bodyParts,
      };
    }

    case 'SET_BODY_PART_INFO': {
      return {
        ...prevState,
        bodyPartInfo: action.payload.bodyPartInfo,
      };
    }

    case 'SET_SHOPPING_CART_ID': {
      return {
        ...prevState,
        shoppingCartId: action.payload.shoppingCartId,
      };
    }
    case 'SET_TOKEN': {
      return {
        ...prevState,
        token: action.payload.token,
      };
    }
    case 'SET_CLIENT_DATA': {
      return {
        ...prevState,
        clientData: action.payload.clientData,
      };
    }

    case 'CLEAR_DATA': {
      return {
        ...prevState,
        ...defaultValues,
      };
    }

    default: {
      return prevState;
    }
  }
};

const anamnesisReducerAtom = atomWithReducer(defaultValues, anamnesisReducer);

export const useAnamnesisState = (): AnamnesisStateValuesWithActions => {
  const [state, dispatch] = useAtom(anamnesisReducerAtom);
  const setReason = useCallback<AnamnesisStateValuesWithActions['setReason']>(
    (reason) => {
      dispatch({
        type: 'SET_REASON',
        payload: {
          reason,
        },
      });
    },
    [],
  );

  const setBodyParts = useCallback<
    AnamnesisStateValuesWithActions['setBodyParts']
  >((bodyParts) => {
    dispatch({
      type: 'SET_BODY_PARTS',
      payload: {
        bodyParts,
      },
    });
  }, []);

  const setBodyPartInfo = useCallback<
    AnamnesisStateValuesWithActions['setBodyPartInfo']
  >((bodyPartInfo) => {
    dispatch({
      type: 'SET_BODY_PART_INFO',
      payload: {
        bodyPartInfo,
      },
    });
  }, []);

  const setShoppingCartId = useCallback<
    AnamnesisStateValuesWithActions['setShoppingCartId']
  >((shoppingCartId) => {
    dispatch({
      type: 'SET_SHOPPING_CART_ID',
      payload: {
        shoppingCartId,
      },
    });
  }, []);

  const setToken = useCallback<AnamnesisStateValuesWithActions['setToken']>(
    (token) => {
      dispatch({
        type: 'SET_TOKEN',
        payload: {
          token,
        },
      });
    },
    [],
  );

  const setClientData = useCallback<
    AnamnesisStateValuesWithActions['setClientData']
  >((clientData) => {
    dispatch({
      type: 'SET_CLIENT_DATA',
      payload: {
        clientData,
      },
    });
  }, []);

  const clearData = useCallback<AnamnesisStateValuesWithActions['clearData']>(
    () =>
      dispatch({
        type: 'CLEAR_DATA',
        payload: null,
      }),
    [],
  );

  return {
    ...state,
    setBodyParts,
    setBodyPartInfo,
    setReason,
    setShoppingCartId,
    setToken,
    setClientData,
    clearData,
  };
};
