import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import '@fortawesome/fontawesome-svg-core';

/* Theme variables */
import '../ui/theme/fonts.css';
import '../ui/theme/variables.scss';
import '../ui/theme/ui.scss';

import { ApolloProvider } from '@apollo/client';
import { Preferences as Storage } from '@capacitor/preferences';
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import injectables from 'application/injectables';
import BookingAddress from 'application/pages/BookingAddress';
import CreateReview from 'application/pages/CreateReview';
import ExpertProfilePage from 'application/pages/ExpertProfile';
import HotelBookingRequest from 'application/pages/HotelBookingRequest/HotelBookingRequest';
import HandleLocationRedirect from 'application/pages/Locations/HandleLocationRedirect';
import Membership from 'application/pages/Membership/Membership';
import NewsletterConfirmedPage from 'application/pages/NewsletterConfirmedPage';
import PasswordReset from 'application/pages/PasswordReset';
import VoucherContact from 'application/pages/VoucherContact';
import VoucherPayment from 'application/pages/VoucherPayment';
import Vouchers from 'application/pages/Vouchers';
import type { ITrackingService } from 'application/services/ITrackingService';
import AuthProvider from 'application/state/AuthProvider';
import { VoucherContextProvider } from 'application/state/VoucherContext';
import apolloClient from 'infrastructure/apollo';
import { useInject } from 'inversify-hooks';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import AppUrlListener from './AppUrlListener';
import Account from './pages/Account/Account';
import BookingContact from './pages/BookingContact';
import BookingDetails from './pages/BookingDetails';
import BookingPayment from './pages/BookingPayment';
import BookingSummary from './pages/BookingSummary';
import SPABookingSummary from './pages/BookingSummary/SPABookingSummary';
import CreateReviewFromLink from './pages/CreateReview/CreareReviewFromLink';
import EditProfile from './pages/EditProfile/EditProfile';
import Home from './pages/Home/Home';
import Locations from './pages/Locations/Locations';
import Login from './pages/Login/Login';
import MyBookings from './pages/MyBookings/MyBookings';
import NotFoundPage from './pages/NotFound/NotFound';
import Onboarding from './pages/Onboarding';
import PasswordChange from './pages/PasswordChange/PasswordChange';
import ConfirmAccount from './pages/Register/ConfirmAccount';
import Register from './pages/Register/Register';
import RegisterExternally from './pages/Register/RegisterExternally';
import SavedCards from './pages/SavedCards';
import Settings from './pages/Settings';
import SofortPayment from './pages/SofortPayment';
import MultipleExpertsBookingSummary from './pages/Treatments/MultipleExperts/MultipleExpertsBookingSummary';
import NextExpertSelection from './pages/Treatments/MultipleExperts/NextExpertSelection';
import TreatmentDetailsPage from './pages/TreatmentTypeDetails';
import VoucherDetails from './pages/VoucherDetails';
import PaymentCallbackHandler from './PaymentCallbackHandler';
import ServiceContainer from './ServiceContainer';
import AssistedStretch from './pages/AssistedStretch/AssistedStretch';
import AssistedStretchSearch from './pages/AssistedStretch/AssistedStretchSearch';
import TreatmentSelection from './pages/TreatmentSelection/TreatmentSelection';
import HandleStretchRedirect from './pages/AssistedStretch/HandleStretchRedirect';
import ClientAnamnesis from './pages/ClientAnamnesis/ClientAnamnesis';
import AnamnesisForm from './pages/ClientAnamnesis/AnamnesisForm';
import AnamnesisSuccess from './pages/ClientAnamnesis/AnamnesisSuccess';

setupIonicReact({
  animated: !isPlatform('desktop'),
});

const CUSTOM_TAG_TRACKING = 'customTagTracking';
const useDetectCustomTag = () => {
  const location = useLocation();
  useEffect(() => {
    void (async () => {
      const urlParams = new URLSearchParams(location.search);
      const customTag = urlParams.get('customTag');

      if (customTag) {
        await Storage.set({
          key: CUSTOM_TAG_TRACKING,
          value: customTag,
        });
      }
    })();
  }, [location]);
};

const RouterOutlet: React.FC = () => {
  const location = useLocation();
  const [trackingService] = useInject<ITrackingService>(
    injectables.services.TrackingService,
  );
  useDetectCustomTag();
  useEffect(() => {
    if (
      location.pathname.startsWith('/payment') ||
      location.pathname.startsWith('/bookings') ||
      location.pathname.startsWith('/treatmentDetails')
    ) {
      return;
    }
    trackingService.trackEvent({
      name: 'page_view',
      data: {
        url: location.pathname,
        params: {},
      },
    });
  }, [location]);

  return (
    <IonRouterOutlet>
      <Route exact path="/membership">
        <Membership />
      </Route>
      <Route exact path="/newsletterConfirmed">
        <NewsletterConfirmedPage />
      </Route>
      <Route exact path="/onboarding">
        <Onboarding />
      </Route>
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/bookings">
        <MyBookings />
      </Route>
      <Route exact path="/treatments">
        <TreatmentSelection />
      </Route>
      <Route exact path="/stretch-treatments">
        <AssistedStretchSearch />
      </Route>
      <Route exact path="/assisted-stretch">
        <AssistedStretch />
      </Route>
      <Route exact path="/stretch/:locationId">
        <HandleStretchRedirect />
      </Route>
      <Route exact path="/vouchers">
        <Vouchers />
      </Route>
      <Route exact path="/vouchers/contact">
        <VoucherContact />
      </Route>
      <Route exact path="/vouchers/payment">
        <VoucherPayment />
      </Route>
      <Route exact path="/vouchers/finished">
        <VoucherDetails />
      </Route>
      <Route exact path="/review/:bookingId">
        <CreateReview />
      </Route>
      <Route exact path="/review/:bookingId/:bookingToken">
        <CreateReviewFromLink />
      </Route>
      <Route exact path="/bookings/:shoppingCartId/:token">
        <BookingDetails />
      </Route>
      <Route exact path="/booking/expert-select">
        <NextExpertSelection />
      </Route>
      <Route exact path="/booking/experts-summary">
        <MultipleExpertsBookingSummary />
      </Route>
      <Route exact path="/booking/summary">
        <BookingSummary />
      </Route>
      <Route exact path="/spa-booking/summary">
        <SPABookingSummary />
      </Route>
      <Route exact path="/booking/contact">
        <BookingContact />
      </Route>
      <Route exact path="/booking/payment">
        <BookingPayment />
      </Route>
      <Route exact path="/booking/address">
        <BookingAddress />
      </Route>
      <Route exact path="/expert/:expertId">
        <ExpertProfilePage />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/passwordReset">
        <PasswordReset />
      </Route>
      <Route exact path="/account">
        <Account />
      </Route>
      <Route exact path="/settings">
        <Settings />
      </Route>
      <Route exact path="/savedCards">
        <SavedCards />
      </Route>
      {/* <Route exact path="/referral"> */}
      {/*   <Referral /> */}
      {/* </Route> */}
      <Route exact path="/register/confirm">
        <ConfirmAccount />
      </Route>
      <Route exact path="/register/external">
        <RegisterExternally />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/passwordChange">
        <PasswordChange />
      </Route>
      <Route exact path="/editProfile">
        <EditProfile />
      </Route>
      <Route
        exact
        path="/payment/sofort/:subject/:targetId/:paymentIntentSecret/:secret?"
      >
        <SofortPayment />
      </Route>
      <Route path="/payment/callback">
        <PaymentCallbackHandler />
      </Route>
      <Route exact path="/treatmentDetails/:treatmentId">
        <TreatmentDetailsPage />
      </Route>
      <Route exact path="/locations/:locationId">
        <HandleLocationRedirect />
      </Route>
      <Route exact path="/locations">
        <Locations />
      </Route>
      <Route exact path="/anamnesis">
        <ClientAnamnesis />
      </Route>
      <Route exact path="/anamnesis-form">
        <AnamnesisForm />
      </Route>
      <Route exact path="/anamnesis/success">
        <AnamnesisSuccess />
      </Route>
      <Route exact path="/hotelBooking/:hotelId/:token">
        <HotelBookingRequest />
      </Route>
      <Route exact path="/404">
        <NotFoundPage />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Redirect to="/404" />
    </IonRouterOutlet>
  );
};

const App: React.FC = () => (
  <ServiceContainer>
    <ApolloProvider client={apolloClient}>
      <IonApp>
        <IonReactRouter>
          <AppUrlListener />
          <AuthProvider>
            <VoucherContextProvider>
              <RouterOutlet />
            </VoucherContextProvider>
          </AuthProvider>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  </ServiceContainer>
);

export default App;
