import type {
  Booking,
  BookingPriceInfo,
  BookingPriceInfoComponent,
  Cart,
  ExpertBriefData,
  Modify,
  ObjectID,
  TreatmentType,
  WithExpert,
  WithTreatmentType,
} from './index';
import currency from 'currency.js';
import { parseISO } from 'date-fns';

export type StoredBooking = Modify<
  WithExpert<WithTreatmentType<Booking>>,
  {
    updatedAt: string;
    createdAt: string;
    customId: string;
    time: string;
    hasReview: boolean;
    price: Modify<
      BookingPriceInfo,
      {
        total: number | string;
        totalTax: number | string;
        components: Modify<
          BookingPriceInfoComponent,
          {
            value: number | string;
          }
        >[];
      }
    >;
    isWithoutOffset?: boolean;
  }
>;

export type CartBooking = {
  expert: ExpertBriefData | null;
  treatmentType: TreatmentType;
  id: ObjectID;
};

export type StoredCart = Cart & {
  price: {
    total: number | string;
    totalTax: number | string;
    components: Modify<
      BookingPriceInfoComponent,
      {
        value: number | string;
      }
    >[];
  };
  hasReview: boolean;
  isInSpa: boolean;
  bookings: CartBooking[];
  address: Booking['address'];
  time: string;
  length: Booking['length'];
  details: Booking['details'];
  updatedAt: string;
  createdAt: string;
  locationId?: string;
  isWithoutOffset?: boolean;
};

export type BookingAndCartCombined = {
  customId: string;
  client?: ObjectID;
  clientData?: { email: string };
  isCart: boolean;
  status: 'waitingForPayment' | 'paid';
  token: string;
  price: {
    total: currency;
    totalTax: currency;
    components: Modify<
      BookingPriceInfoComponent,
      {
        value: currency;
      }
    >[];
  };
  hasReview: boolean;
  bookings: CartBooking[];
  address: Booking['address'];
  time: Date;
  length: Booking['length'];
  updatedAt: Date;
  createdAt: Date;
  locationId?: string;
  id: ObjectID;
  isWithoutOffset?: boolean;
};

export function mapBookingJSONToObject(
  booking: StoredBooking,
): BookingAndCartCombined {
  return {
    client: booking.client,
    isCart: false,
    customId: booking.customId,
    id: booking.id,
    hasReview: booking.hasReview,
    status: booking.status === 'paid' ? booking.status : 'waitingForPayment',
    token: booking.token,
    bookings: [booking],
    address: booking.address,
    createdAt: parseISO(booking.createdAt),
    updatedAt: parseISO(booking.updatedAt),
    time: parseISO(booking.time),
    length: booking.length,
    price: {
      total: currency(booking.price.total),
      totalTax: currency(booking.price.totalTax),
      components: booking.price.components.map((component) => ({
        ...component,
        value: currency(component.value),
      })),
    },
    isWithoutOffset: booking.isWithoutOffset,
  };
}

export function mapCartJSONToObject(cart: StoredCart): BookingAndCartCombined {
  return {
    bookings: cart.bookings,
    clientData: cart.clientData,
    client: cart.client,
    id: cart.id,
    isCart: true,
    createdAt: parseISO(cart.createdAt),
    updatedAt: parseISO(cart.updatedAt),
    address: cart.address,
    locationId: cart.locationId,
    length: cart.length,
    customId: cart.customId,
    hasReview: cart.hasReview,
    status: cart.status,
    token: cart.token,
    price: {
      total: currency(cart.price.total),
      totalTax: currency(cart.price.totalTax),
      components: cart.price.components.map((component) => ({
        ...component,
        value: currency(component.value),
      })),
    },
    time: parseISO(cart.time),
    isWithoutOffset: cart.isWithoutOffset,
  };
}

export default mapBookingJSONToObject;
