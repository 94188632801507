import { Control, useController } from 'react-hook-form';
import styles from './anamnesis.module.scss';
import cx from 'classnames';
import { useState } from 'react';
import useConfig from './questionnaireFormConfig';
import { useContextTranslation } from '../../../ui/translation';

const VisitReasonForm = ({ control }: { control: Control<{ reasons: string[] }> }) => {
  const questionnaireFormConfig = useConfig();
  const { field } = useController({
    control,
    name: 'reasons',
  });

  //TODO replace with global store?
  const [value, setValue] = useState(field.value || []);
  const t = useContextTranslation('page.anamnesis.reasons');

  return (
    <div className={styles.visitReason}>
      <h3 className={styles.anamnesisFormHeading}>
        {t('heading')}
      </h3>
      {
        questionnaireFormConfig.reasons.map((reason) => {
          return (
            <label key={reason} aria-label={reason}>
              <div
                className={cx({
                  [styles.reasonItem]: true,
                  [styles.checked]: value.includes(reason),
                })}
              >
                <p className={styles.buttonLabel}>
                  {t(reason)}
                </p>
              </div>
              <input
                id={reason}
                className={styles.hiddenCheckBox}
                onChange={(e) => {
                  let valueCopy = [...value];
                  if (e.target.checked) {
                    valueCopy = [...valueCopy, e.target.value];
                  }
                  if (!e.target.checked) {
                    valueCopy = valueCopy.filter(val => (val !== e.target.value));
                  }
                  field.onChange(valueCopy);
                  setValue(valueCopy);
                }}
                key={reason}
                type="checkbox"
                checked={value.includes(reason)}
                value={reason}
              />
            </label>
          );
        })
      }
    </div>
  );
};

export default VisitReasonForm;