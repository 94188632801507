import './bookingCard.scss';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonAlert,
  IonAvatar,
  IonItem,
  IonList,
  IonLoading,
  IonPopover,
  useIonAlert,
} from '@ionic/react';
import type { ITreatmentAdapter } from 'application/pages/Treatments/ITreatmentAdapter';
import type IAnonymousBookingsService from 'application/services/IAnonymousBookingsService';
import { useTreatmentBuilder } from 'application/state/TreatmentContext';
import type { BookingAndCartCombined } from 'application/types/mapBookingJSONToObject';
import { addMinutes, format, isAfter, isBefore, subMinutes } from 'date-fns';
import { useInject } from 'inversify-hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  TranslatableFormatter,
  useContextTranslation,
  useTranslateString,
} from 'ui/translation';
import currencyFormat from 'ui/utils/currencyFormat';

import injectables from '../../application/injectables';
import type IAccountBookingsAdapter from '../../application/pages/MyBookings/IAccountBookingsAdapter';
import LocationFormatter from './LocationFormatter';
import Button from 'ui/elements/Button/Button';

interface BookingCardProps {
  booking: BookingAndCartCombined;
  pastBooking?: boolean;
  onSelect: (isCall?: boolean) => void;
}

const BOOKING_CANCEL_OFFSET = 24 * 60;
const BOOKING_CALL_OFFSET = 30;

type PastBookingCardProps = {
  booking: BookingAndCartCombined;
};

const PastBookingCardFooter = ({ booking }: PastBookingCardProps) => {
  const [present] = useIonAlert();
  const t = useContextTranslation('page.my_bookings');
  const history = useHistory();

  const [service] = useInject<ITreatmentAdapter>(
    injectables.pages.TreatmentAdapter,
  );
  const treatmentLengths = service.useTreatmentLengths(
    booking.bookings[0].treatmentType.id,
  );
  const treatmentTypes = service.useTreatmentTypes();
  const { setType, setLength, setLocation } = useTreatmentBuilder();
  const bookingsWithExpert = booking.bookings.filter((b) => !!b.expert);

  const onRebook = () => {
    if (
      booking.bookings.length < 2 &&
      !treatmentLengths.loading &&
      treatmentLengths.value &&
      !treatmentTypes.loading &&
      treatmentTypes.value
    ) {
      const treatmentLength = treatmentLengths.value.find(
        (e) => e.length === booking.length,
      );
      const treatmentType = treatmentTypes.value.find(
        (e) => e.id === booking.bookings[0].treatmentType.id,
      );
      if (treatmentLength && treatmentType) {
        setType(treatmentType);
        setLength(treatmentLength);
        setLocation(booking.address);
        history.push('/treatments');
      } else {
        void present({
          message: t('no_treatment_type'),
          buttons: [{ text: 'Ok' }],
        });
      }
    }
  };

  return (
    <div className="past-booking-footer">
      {Boolean(bookingsWithExpert.length) && (
        <>
          <Button
            disabled={
              booking.hasReview ||
              !booking.client ||
              isBefore(new Date(), addMinutes(booking.time, booking.length))
            }
            className="footer-btn"
            routerLink={`/review/${booking.id}`}
          >
            {booking.hasReview ? t('booking_has_review') : t('review_button')}
          </Button>
          {/* // TODO Multiple booking rebook */}
          {booking.bookings.length < 2 && (
            <Button onClick={onRebook}>{t('rebook_button')}</Button>
          )}
        </>
      )}
    </div>
  );
};

const BookingCard: React.FC<BookingCardProps> = ({
  booking,
  pastBooking,
  onSelect,
}) => {
  const t = useContextTranslation('page.my_bookings');
  const mt = useContextTranslation('misc');

  const [submenuState, setSubmenuState] = React.useState<{
    isOpen: boolean;
    event?: Event;
  }>({ isOpen: false });

  const [cancelBookingModalOpened, setCancelBookingModalOpened] =
    React.useState<boolean>(false);

  const [bookingAdapter] = useInject<IAccountBookingsAdapter>(
    injectables.pages.AccountBookingsAdapter,
  );
  const [anonymousBookingsService] = useInject<IAnonymousBookingsService>(
    injectables.services.AnonymousBookingsService,
  );

  const [cancelPending, setCancelPending] = React.useState<boolean>(false);

  const [showAlert] = useIonAlert();

  const cancelDisabled = isAfter(
    new Date(),
    subMinutes(booking.time, BOOKING_CANCEL_OFFSET),
  );

  const callDisabled = Boolean(
    isBefore(new Date(), subMinutes(booking.time, BOOKING_CALL_OFFSET)) ||
      isAfter(
        new Date(),
        addMinutes(booking.time, BOOKING_CALL_OFFSET + booking.length),
      ),
  );

  const translate = useTranslateString();
  const nameHeader =
    booking.bookings[1]?.treatmentType?.name &&
    booking.bookings[0].treatmentType?.name ? (
      `${translate(booking.bookings[0].treatmentType?.name)} & ${translate(
        booking.bookings[1].treatmentType?.name,
      )}`
    ) : (
      <TranslatableFormatter value={booking.bookings[0].treatmentType?.name} />
    );

  const bookingsWithExpert = booking.bookings.filter((b) => !!b.expert);

  return (
    <>
      <div className={`booking-card${pastBooking ? ' past' : ''}`}>
        <div className="content">
          <h4>{nameHeader}</h4>
          <div className="time">
            {pastBooking
              ? mt('length_format_price', {
                  length: booking.length,
                  price: currencyFormat(booking.price.total),
                })
              : mt('time_format', {
                  time: format(booking.time, 'dd.MM.yy, H:mm'),
                })}
          </div>
          {!pastBooking && (
            <>
              <div className="length">
                {mt('length_format', { length: booking.length })}
              </div>
              <div className="location">
                <LocationFormatter location={booking.address} />
              </div>
            </>
          )}
        </div>
        <div className="expert-info">
          <div className="submenu">
            <IonPopover
              isOpen={submenuState.isOpen}
              event={submenuState.event}
              onDidDismiss={() => setSubmenuState({ isOpen: false })}
            >
              <IonList className="submenu-list">
                <IonItem
                  button
                  lines="none"
                  onClick={() => {
                    setSubmenuState({ isOpen: false });
                    onSelect();
                  }}
                  mode="md"
                >
                  {t('treatment_details')}
                </IonItem>
                {Boolean(bookingsWithExpert.length) && (
                  <IonItem
                    button
                    lines="none"
                    routerLink={`/expert/${booking.bookings[0].expert?.id}`}
                    onClick={() => setSubmenuState({ isOpen: false })}
                    mode="md"
                  >
                    {t('expert_profile')}
                  </IonItem>
                )}
                {Boolean(bookingsWithExpert.length) && (
                  <IonItem
                    button
                    lines="none"
                    onClick={() => {
                      setSubmenuState({ isOpen: false });
                      onSelect(true);
                    }}
                    mode="md"
                    disabled={callDisabled}
                  >
                    {t('contact_expert')}
                  </IonItem>
                )}
                <IonItem
                  button
                  disabled={cancelDisabled}
                  lines="none"
                  onClick={() => {
                    setSubmenuState({ isOpen: false });
                    setCancelBookingModalOpened(true);
                  }}
                  mode="md"
                >
                  {cancelDisabled
                    ? t('booking_too_soon_to_cancel')
                    : t('cancel_booking')}
                </IonItem>
              </IonList>
            </IonPopover>
            <button
              className="more-button"
              type="button"
              onClick={(e) =>
                setSubmenuState({ isOpen: true, event: e.nativeEvent })
              }
              aria-label={t('more')}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </button>
          </div>

          <>
            <div className="avatar-wrapper">
              {bookingsWithExpert.map((singleBooking) => (
                <IonAvatar key={singleBooking.expert?.id}>
                  <img
                    src={singleBooking.expert?.profilePictureUrl}
                    alt={mt('profile_picture')}
                  />
                </IonAvatar>
              ))}
            </div>
            <div className="name">
              {bookingsWithExpert.map((sb) => sb.expert?.name).join(', ')}
            </div>
          </>
        </div>
        <IonAlert
          isOpen={cancelBookingModalOpened}
          message={t('cancel_confirmation_message')}
          onDidDismiss={() => setCancelBookingModalOpened(false)}
          buttons={[
            {
              text: t('no'),
              role: 'cancel',
            },
            {
              text: t('yes'),
              handler: async () => {
                try {
                  setCancelPending(true);
                  if (booking.isCart) {
                    await bookingAdapter.cancelCart(booking.id, booking.token);
                    await anonymousBookingsService.removeBooking(booking.id);
                  }
                  if (!booking.isCart) {
                    await bookingAdapter.cancelBooking(
                      booking.id,
                      booking.token,
                    );
                    await anonymousBookingsService.removeBooking(booking.id);
                  }

                  setCancelPending(false);
                  await showAlert({
                    header: t('booking_cancelled'),
                    message: t('compensation_info'),
                    buttons: [
                      {
                        role: 'cancel',
                        text: t('dismiss'),
                      },
                    ],
                  });
                } catch (e) {
                  setCancelPending(false);
                  await showAlert({
                    header: t('error_while_cancelling'),
                    buttons: [
                      {
                        role: 'cancel',
                        text: t('dismiss'),
                      },
                    ],
                  });
                }
              },
            },
          ]}
        />
        <IonLoading
          isOpen={cancelPending}
          message={t('cancelling_in_progress')}
        />
      </div>
      {pastBooking && <PastBookingCardFooter booking={booking} />}
    </>
  );
};

export default BookingCard;
