import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import Button from '../../../ui/elements/Button/Button';
import { useContextTranslation } from '../../../ui/translation';
import SubPageLayout from '../../../ui/layout/SubPageLayout';
import styles from './anamnesis.module.scss';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import useConfig from './questionnaireFormConfig';
import { useAnamnesisState } from './AnamnesisStore';
import { useHistory } from 'react-router';

const getAnamnesisErrorMessage = (error: string) => {
  if (error === 'reasons') {
    return 'select_reasons';
  }
  if (error === 'bodyParts') {
    return 'select_bodyParts';
  }
  if (error === 'back') {
    return "select_back_part_error"
  }
  return 'select_side_error';

};

const AnamnesisForm = () => {
  const t = useContextTranslation('page.anamnesis');
  const [formPage, setPage] = useState(0);
  const questionnaireFormConfig = useConfig();
  const CurrentStep = questionnaireFormConfig.form[formPage];

  const { formState: { errors }, handleSubmit, control } = useForm({
    resolver: zodResolver(CurrentStep?.schema),
  });
  const history = useHistory();
  const { clientData, token, shoppingCartId } = useAnamnesisState();

  useIonViewWillEnter(() => {
    if (!clientData || !token || !shoppingCartId) {
      history.replace('/bookings');
    }
  }, [clientData, token, shoppingCartId]);

  return (
    <SubPageLayout className={styles.anamnesisLayout} onGoBack={() => {
      CurrentStep.onBack(setPage);
    }}
    >
      <form onSubmit={handleSubmit((value) => {
        CurrentStep.onSubmit(setPage, value);
      })}
            className={styles.anamnesisForm}
      >
        <IonGrid className={styles.anamnesisContent}>
          <IonRow>
            <IonCol>
              <CurrentStep.component control={control} />
            </IonCol>
          </IonRow>
          {errors && Object.keys(errors)[0] && (
            <div className={styles.error}>{t(getAnamnesisErrorMessage(Object.keys(errors)[0]))}</div>
          )}
          <IonRow className={styles.anamnesisFooter}>
            <IonCol>
              <Button
                color="primary"
                type={'submit'}
              >
                {t('next')}
              </Button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </SubPageLayout>
  );
};

export default AnamnesisForm;