import 'application/pages/BookingDetailsModal/bookingDetailsModal.scss';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCol, IonContent, IonFooter, IonGrid, IonRow } from '@ionic/react';
import useAddCartToCalendar from 'application/state/useAddToCalendar';
import type {
  TranslatableString,
  TranslatableStringLanguage,
} from 'application/types';
import * as React from 'react';
import { useEffect } from 'react';
import type { TFunction } from 'react-i18next';
import CartSummaryTable from 'ui/elements/CartSummaryTable';
import CustomModal from 'ui/elements/CustomModal/CustomModal';
import {
  useContextTranslation,
  useTranslateOptionalString,
} from 'ui/translation';

import type { BookingAndCartCombined } from '../../types/mapBookingJSONToObject';
import useLocationConfirmationText from './useLocationConfirmationText';
import Button from 'ui/elements/Button/Button';
import { ReactComponent as CloseIcon } from 'ui/theme/images/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import { useAnamnesisState } from '../ClientAnamnesis/AnamnesisStore';
import { gql, useQuery } from '@apollo/client';

// TODO: refactor required
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

interface BookingDetailsModalProps {
  isOpen: boolean;
  cart: BookingAndCartCombined | null;
  onClose: () => void;
}

const massageDetailsText = (
  t: TFunction,
  tr: (value: TranslatableStringLanguage[]) => string | undefined,
  location: any,
  numberOfExperts: number,
): string[] => {
  if (location) {
    return location.map((el: TranslatableString) => tr(el));
  }
  if (numberOfExperts > 1) {
    return [
      t('treatment_info_space_multi'),
      t('treatment_info_towels_multi'),
      t('treatment_info_bath_multi'),
    ];
  }
  return [
    t('treatment_info_space'),
    t('treatment_info_towels'),
    t('treatment_info_bath'),
  ];
};

const SHOULD_CLIENT_UPDATE_ANAMNESIS = gql`
  query shouldClientUpdateAnamnesis($clientEmail: ID) {
      shouldClientUpdateAnamnesis(clientEmail: $clientEmail)
  }
`

const useShouldUpdateAnamnesis = (clientEmail?: string) => {
  const query = useQuery<{ shouldClientUpdateAnamnesis: boolean }>(SHOULD_CLIENT_UPDATE_ANAMNESIS, {
    variables: {
      clientEmail
    },
    skip: !clientEmail,
    fetchPolicy: 'network-only',
  });
  return {
    shouldUpdateAnamnesis: Boolean(query?.data?.shouldClientUpdateAnamnesis),
    shouldUpdateAnamnesisLoading: query.loading
  }
}

const CartDetailsModal: React.FC<BookingDetailsModalProps> = (
  {
    isOpen,
    onClose,
    cart,
  }) => {
  const t = useContextTranslation('page.booking_details');
  const mt = useContextTranslation('misc');
  const [isAddedToCalendar, setIsAddedToCalendar] = React.useState(false);

  const { getLocationConfirmationText, value: locationConfirmationTexts } =
    useLocationConfirmationText();

  const { shouldUpdateAnamnesis, shouldUpdateAnamnesisLoading } =useShouldUpdateAnamnesis(cart?.clientData?.email)

  useEffect(() => {
    if (cart?.locationId) {
      getLocationConfirmationText({ variables: { id: cart.locationId } });
    }
  }, [cart?.locationId]);
  const addToCalendar = useAddCartToCalendar(cart);
  const translate = useTranslateOptionalString();
  const history = useHistory();
  const { setToken, setShoppingCartId, setClientData } = useAnamnesisState();

  if (!cart || shouldUpdateAnamnesisLoading) {
    return <></>;
  }

  const expertTreatments = cart.bookings.map((treatment) => ({
    type: treatment.treatmentType,
    expert: treatment.expert,
  }));

  return (
    <CustomModal
      backdropDismiss={false}
      isOpen={isOpen}
      cssClass="booking-modal booking-details-modal"
    >
      <IonContent>
        <button
          type="button"
          aria-label={mt('close')}
          className="close-button"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <FontAwesomeIcon className="header-icon" icon={faCheckCircle} />
              <p className="message">{t('message')}</p>
              <div className="code">{t('code', { code: cart.customId })}</div>
            </IonCol>
            <IonCol size="12">
              <CartSummaryTable
                time={cart.time}
                expertTreatments={expertTreatments}
                length={{
                  id: '',
                  length: cart.length,
                  price: 0,
                }}
                location={cart.address}
              />
            </IonCol>
            <IonCol size="12" className="treatment_info">
              <h4>{t('treatment_info_heading')}</h4>
              <ul>
                {massageDetailsText(
                  t,
                  translate,
                  cart?.locationId ? locationConfirmationTexts : null,
                  cart.bookings.length,
                ).map((el) => (
                  <li key={el}>
                    <span>{el}</span>
                  </li>
                ))}
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <div className="content">
          <Button
            onClick={() => {
              if (isAddedToCalendar) {
                history.replace('/home', { direction: 'forward' });
                return;
              }
              addToCalendar(setIsAddedToCalendar);
            }}
            color="primary"
            className={`${shouldUpdateAnamnesis ? 'transparentBtn' : ''}`}
          >
            {isAddedToCalendar ? t('done') : t('calendar_save')}
          </Button>
          {shouldUpdateAnamnesis && (
            <Button
              onClick={() => {
                setToken(cart?.token);
                setShoppingCartId(cart?.id);
                setClientData({
                  // TODO update types
                  clientEmail: cart?.clientData?.email || '',
                  clientId: cart?.client || '',
                  lastShoppingCartToken: cart?.token,
                });
                history.replace('/anamnesis', { direction: 'forward' });
              }}
              color="primary"
            >
              {t('health_details')}
            </Button>
          )}

        </div>
      </IonFooter>
    </CustomModal>
  );
};

export default CartDetailsModal;
